<template>
  <div class="box">
  <div class="all">
    <div class="center">
      <div class="title">SCORE</div>
      <div class="num">
        <CountTo :startVal='startVal' :endVal='score' :duration='duration' :autoplay="false" ref="countTo"
                   />
      </div>
    </div>
  </div>
</div>
</template>

<script>
import CountTo from 'vue-count-to'
export default {
  components: {
    CountTo
  },
  name: 'PaGe2',
  data() {
    return {
      score:0,
      startVal: 0
    }
  },
  mounted() {
    window.getMessage = this.getMessage;//获取推送数据
    this.score=Number(this.$route.query.score);
    this.startVal=Number(this.$route.query.startVal);
    setTimeout(this.valChange, 1)
    localStorage.setItem('score', this.score);
    console.log(this.$route.query)
    document.body.style.zoom=1
    setTimeout(() => {
          this.$router.push({ path: '/page10'})
        }, 30000);
  }, methods: {
    getMessage(str) {
      const strs = str.split('-')
      if (strs[0] == 'start') {
      this.$router.push({ path: '/page10',query:{str:str} })
      }
      if (strs[0] == 'jump') {
      this.$router.push({ path: '/page3',query:{str:str} })
      }
    },
    valChange() {
      this.$refs.countTo.start();
    },
  }
}
</script>
<style scoped>
.all {
  zoom: 0.5;
}
.box{
  width: 100%;
  height: 100vh;
  background: rgb(6, 235, 64);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
</style>
<style scoped>
.all {
  width: 100%;
  background: rgb(6, 235, 64);
  height: 100vh;
  font-family: "bth";
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.title {
  font-size: 155px;
  color: #FFFFFF;
}

.num {
  font-size: 437px;
  color: #FFFFFF;
  height: 437px;
  line-height: 437px;
}
</style>
