// 引入Vue核心库
import Vue from "vue";
// 引入vue-router
import VueRouter from "vue-router";
// 应用vue-router插件
Vue.use(VueRouter);

// 引入组件（方式一）
import page1 from "../views/page1.vue";
import page2 from "../views/page2.vue";
import page3 from "../views/page3.vue";
import page4 from "../views/page4.vue";
import page5 from "../views/page5.vue";
import page6 from "../views/page6.vue";
import page7 from "../views/page7.vue";
import page8 from "../views/page8.vue";
import page9 from "../views/page9.vue";
// import page10 from "../views/page10.vue";
import page11 from "../views/page11.vue";
import rank from "../views/rank.vue";
// import nanjing from "../views/nanjing.vue";
// import score from "../views/score.vue";
import xiyatu from "../views/xiyatu.vue";
import scoreXiyatu from "../views/score-xiyatu.vue";
//美国界面
// import score from "../views/score-xiyatu.vue";
// import page10 from "../views/score-xiyatu.vue";
// import nanjing from "../views/xiyatu.vue";
//国内界面
import page10 from "../views/page10.vue";
import nanjing from "../views/nanjing.vue";
import score from "../views/score.vue";

// 配置路由
const routes = [
  {
    // 路径，/ 表示首页
    path: "/score-xiyatu",
    // 使用组件（方式一）
    component: scoreXiyatu,
  },
  {
    // 路径，/ 表示首页
    path: "/xiyatu",
    // 使用组件（方式一）
    component: xiyatu,
  },
  {
    // 路径，/ 表示首页
    path: "/score",
    // 使用组件（方式一）
    component: score,
  },
  {
    // 路径，/ 表示首页
    path: "/nanjing",
    // 使用组件（方式一）
    component: nanjing,
  },
  {
    // 路径，/ 表示首页
    path: "/",
    // 使用组件（方式一）
    component: page1,
  },
  {
    // 路径，/ 表示首页
    path: "/page2",
    // 使用组件（方式一）
    component: page2,
  },
  {
    // 路径，/ 表示首页
    path: "/page3",
    // 使用组件（方式一）
    component: page3,
  },
  {
    // 路径，/ 表示首页
    path: "/page4",
    // 使用组件（方式一）
    component: page4,
  },
  {
    // 路径，/ 表示首页
    path: "/page5",
    // 使用组件（方式一）
    component: page5,
  },
  {
    // 路径，/ 表示首页
    path: "/page6",
    // 使用组件（方式一）
    component: page6,
  },
  {
    // 路径，/ 表示首页
    path: "/page7",
    // 使用组件（方式一）
    component: page7,
  },
  {
    // 路径，/ 表示首页
    path: "/page8",
    // 使用组件（方式一）
    component: page8,
  },
  {
    // 路径，/ 表示首页
    path: "/page9",
    // 使用组件（方式一）
    component: page9,
  },
  {
    // 路径，/ 表示首页
    path: "/page10",
    // 使用组件（方式一）
    component: page10,
  },
  {
    // 路径，/ 表示首页
    path: "/page11",
    // 使用组件（方式一）
    component: page11,
  },
  {
    // 路径，/ 表示首页
    path: "/rank",
    // 使用组件（方式一）
    component: rank,
  },
];

// 创建路由器
const router = new VueRouter({
  routes,
});

// 导出路由器
export default router;
